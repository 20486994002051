import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import "../../components/Signup/Signup.css";

const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/profile";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  // Call to back-end to get config
   React.useEffect(() => {
    fetch("/firebaseconfig")
      .then((res) => res.json())
      .then((firebaseconfig) => initializeApp(firebaseconfig));
  }, []);

  // Call to back-end to check session cookie
  useEffect(() => {
    fetch("/checkcookie")
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        if(data.code === "401") {
          // Session cookie not found, proceed with the signin form below
        }
        else if(data.code === "200") {
          // User is already signed in, redirect to profile component
          navigate('/profile');
        }
      })
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      var userCredential = await signInWithEmailAndPassword(auth, email, password);
      // User signed in successfully
      console.log('User signed in');
      // Reset form fields and error state
      setEmail('');
      setPassword('');
      setErrorMessage(null);

      // Get the Firebase ID token of the signed-in user
      const idToken = await userCredential.user.getIdToken();

      // Generate session cookie with the idToken
      await fetch('/generateSessionCookie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      // Navigate the user to the profile page and do a reload
      navigate(from, { replace: true });
      window.location.reload();

    } catch (error) {
      // Handle sign-in error
      setErrorMessage("Error signing in");
    }
  };

  return (
    <div>

        <div className="form-title">
        Sign in
        </div>

        <div className="form-elements">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div>
            <label htmlFor="email">Email</label>
            </div>
            <div>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            </div>
            </div>
            <div className="form-group">
              <div>
              <label htmlFor="password">Password</label>
              </div>
              <div>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              </div>
            </div>
            <button className="signup-btn" type="submit">Sign in</button>     
            {errorMessage && <div className="error">{errorMessage}</div>}  
            <div className="forgotpassword">
            <Link to="/forgotpassword">Forgot your password?</Link>
            </div>    
        </form>
      </div>

    </div>
  );
};

export default Signin;