import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./App.css";
import AudioPlayer from '../AudioPlayer/AudioPlayer'

function App() {
  const [books, setBooks] = React.useState(null);
  const [activeTab, setActiveTab] = useState('onSale');
  const [showDynamicPlans, setShowDynamicPlans] = useState(false);
  const [showStaticPlans, setShowStaticPlans] = useState(false);

  // Call to back-end
  React.useEffect(() => {
    fetch("/books")
      .then((res) => res.json())
      .then((data) => setBooks(data.booklist));
  }, []);

  // Call to back-end to check session cookie
  useEffect(() => {
    fetch("/checkcookie")
      .then((res) => {
        if (res.status === 200) {        
          return res.json();
        }
      })
      .then((data) => {
        if(data.code === "401") {
          // Session cookie not found
          setShowDynamicPlans(true);
        }
        else if(data.code === "200") {
          // User is signed in, check subscriptions          
            const subscriptionResponse = fetch("/getsubscriptions", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email: data.email })
            });

            if (subscriptionResponse.ok) {
              const subscriptionData = subscriptionResponse.json();
              if(subscriptionData.subscriptionsWithProducts.length > 0) {
                setShowDynamicPlans(false);
                setShowStaticPlans(false);
              } else {
                // User exists but does not have any subscriptions, show the static plans
                setShowDynamicPlans(false);
                setShowStaticPlans(true);
              }
            }
        } else {
          // Unhandled error while fetching the session cookie to check if user exists, show the dynamic plans
          setShowDynamicPlans(true);
          setShowStaticPlans(false);
        }
      })
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="App">   
    {showDynamicPlans && <div>
        <div>
        Get Started!
        </div>
        
        <Link
        className="subscription-box"
        to={{
          pathname: "/signup",
          search: `?plan=Vox Machina Basic`
        }}
        >
        <h2>Basic</h2>
        <p className="feature"><span className="icon green">&#10003;</span> Monthly e-book</p>
        <p className="feature"><span className="icon red">&#10007;</span> Monthly Audiobook</p>
        <p className="feature"><span className="icon red">&#10007;</span> Monthly Paperback</p>
        <p className="price">€5/month</p>
        <button className="subscribe-btn">Subscribe</button>
        </Link>        

        <Link
        className="subscription-box"
        to={{
          pathname: "/signup",
          search: `?plan=Vox Machina Pro`
        }}
        >
          <h2>Pro</h2>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly e-book</p>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly Audiobook</p>
          <p className="feature"><span className="icon red">&#10007;</span> Monthly Paperback</p>
          <p className="price">€10/month</p>
          <button className="subscribe-btn">Subscribe</button>
        </Link> 

        <Link
        className="subscription-box"
        to={{
          pathname: "/signup",
          search: `?plan=Vox Machina Proplus`
        }}
        >
          <h2>Pro+</h2>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly e-book</p>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly Audiobook</p>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly Paperback</p>
          <p className="price">€25/month</p>
          <button className="subscribe-btn">Subscribe</button>
        </Link> 
    </div>}

    {showStaticPlans && <div>
        <div>
        Get Started!
        </div>
        
        <a href="https://buy.stripe.com/test_eVa3eL644fSXfL25kk" className="subscription-box" target="_blank">
        <h2>Basic</h2>
        <p className="feature"><span className="icon green">&#10003;</span> Monthly e-book</p>
        <p className="feature"><span className="icon red">&#10007;</span> Monthly Audiobook</p>
        <p className="feature"><span className="icon red">&#10007;</span> Monthly Paperback</p>
        <p className="price">€5/month</p>
        <button className="subscribe-btn">Subscribe</button>
        </a>        

        <a href="https://buy.stripe.com/test_fZeaHd0JKeOT42kbIJ" className="subscription-box" target="_blank">
          <h2>Pro</h2>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly e-book</p>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly Audiobook</p>
          <p className="feature"><span className="icon red">&#10007;</span> Monthly Paperback</p>
          <p className="price">€10/month</p>
          <button className="subscribe-btn">Subscribe</button>
          </a>
                    
          <a href="https://buy.stripe.com/test_5kA5mTgIIeOTcyQ28a" className="subscription-box" target="_blank">
          <h2>Pro+</h2>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly e-book</p>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly Audiobook</p>
          <p className="feature"><span className="icon green">&#10003;</span> Monthly Paperback</p>
          <p className="price">€25/month</p>
          <button className="subscribe-btn">Subscribe</button>
          </a>
    </div>}

      <div>
        Browse Our Books
      </div>

      <div>
        <div className="tabs">
          <div
            className={activeTab === 'onSale' ? 'tab active' : 'tab'}
            onClick={() => handleTabClick('onSale')}
          >
            On Sale
          </div>
          <div
            className={activeTab === 'upcoming' ? 'tab active' : 'tab'}
            onClick={() => handleTabClick('upcoming')}
          >
            Upcoming
          </div>
        </div>
        <div className="book-box">
          {/* Book content */}
          {/* Render books dynamically based on the active tab */}
          {books ? (
            <div className="book-content" style={{ display: activeTab === 'onSale' ? 'flex' : 'none' }}>
              {books
              .filter(book => book.onSale === true)
              .map((book, index) => (
                <div className="book" key={index}>
                  <div><img src={'/images/' + book.imagePath} alt={book.name} /></div>                  
                  <AudioPlayer audioSrc={'/audiobooksamples/' + book.audioBookSamplePath} isSample={true} />                                   
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}

          {books ? (
            <div className="book-content" style={{ display: activeTab === 'upcoming' ? 'flex' : 'none' }}>
              {books
              .filter(book => book.onSale === false)
              .map((book, index) => (
                <div className="book" key={index}>
                  <div><img src={'/images/' + book.imagePath} alt={book.name} /></div>    
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
    </div>         
    </div>
  );
}

export default App;
