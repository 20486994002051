import React, { useState, useEffect } from "react";
import "../../components/LaunchSite/LaunchSite.css";

const LaunchSite = () => {
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(null);
  const [infoMessage, setInfoMessage] = useState("");

  useEffect(() => {
    setValidEmail(email.length === 0 || EMAIL_REGEX.test(email));
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(validEmail) {
      try {
        const response = await fetch('/subscribeToLaunch', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        if (response.status === 201) {
          setInfoMessage("Thanks! You will received an e-mail from us once we launch");          
        } else if (response.status === 403) {
          setInfoMessage("You have already subscribed");  
        } else {
          setInfoMessage("An error occurred, please try again");
        }
      } catch (error) {
        setInfoMessage("An error occurred, please try again");
      }
    } else {
      setInfoMessage("Email is not valid");   
    }
  }    

  return (
    <div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="form-title title">
      Leveraging AI to produce audiobooks
      <div>using the author's voice.</div>
      <div className="undertitle">
        Subscribe and get notification when we launch. 
      </div>
      </div>
      <br/>
        <div className="form-elements">
        <form onSubmit={handleSubmit}>
          <div className="form-group form-email">
            <div className="form-email">
            <label htmlFor="email">Email Address</label>
            </div>
            <div>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            </div>
            </div> 
            <button className="subscribe-btn" type="submit">Subscribe</button>     
            {infoMessage && <div className="infomessage">{infoMessage}</div>}    
        </form>
      </div>      
      <div className="copyright">Legatum Publishing AS. Copyright© 2024. All rights reserved.</div>
    </div>
  );
};

export default LaunchSite;