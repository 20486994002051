import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import Signup from './components/Signup/Signup';
import Signin from './components/Signin/Signin';
import SignupOrSignin from './components/SignupOrSignin/SignupOrSignin';
import Profile from './components/Profile/Profile';
import Signout from './components/Signout/Signout';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import NotFound from './components/NotFound/NotFound';
import GDPRMessage from './components/GDPRMessage/GDPRMessage';
import UpgradePlan from './components/UpgradePlan/UpgradePlan';
import OrderPaperback from './components/OrderPaperback/OrderPaperback';
import LaunchSite from './components/LaunchSite/LaunchSite';
import logo from "./logo.svg";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>  
    {/* Comment out <LaunchSite/> if you want to bypass the launchsite */}
    <LaunchSite/>
    {/* Comment in the code below if you want to bypass the launchsite */}
      {/* <a href="/">
        <header>
            <img src={logo} className="App-logo" alt="logo" />
        </header>
      </a> */}
      {/* <SignupOrSignin />
      <GDPRMessage />
      <Router>
        <Routes> */}
          {/* Define routes */}
          {/* <Route path="/" element={<App />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/upgradeplan" element={<UpgradePlan />} />
          <Route path="/orderpaperback/:id" element={<OrderPaperback/>} />
          <Route path="*" element={<NotFound />} /> */}
          {/* Add more routes as needed */}
        {/* </Routes>
      </Router> */}
      
      {/* <a href="/">
      <footer>
          <img src={logo} className="App-logo" alt="logo" />
          <div className="copyright">Vox Machina. Copyright© 2024. All rights reserved.</div>
      </footer>
      </a> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();